/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { T22 } from "components";
import { Default } from "layouts";
import Helmet from "react-helmet";

const styles = css`
  .content {
    position: relative;
  }

  .t1 {
    font-size: 40px;
    font-weight: 600;
  }

  .t2 {
    font-size: 24px;
    font-weight: 600;
  }

  .t3 {
    font-size: 24px;
  }

  .t4 {
    font-size: 16px;
    font-weight: 600;
  }

  .t5 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  hr {
    max-width: 1537px;
    height: 1px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    margin: 64px auto;
  }
`;

const IndexPage = () => (
  <div css={styles}>
    <Helmet>
      <title>JV–LA | Jack-Edward Oliver</title>
    </Helmet>
    <div className="content">
      <Default>
        <T22.Hero />
        <hr />
        <T22.Journal />
        <hr />
        <T22.Music />
        <hr />
        <T22.Books />
        <hr />
      </Default>
    </div>
  </div>
);

export default IndexPage;
